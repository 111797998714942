.coupon-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    height: 100%;
    max-width: 600px;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This makes sure the footer stays at the bottom */
}

.coupon-body {
    flex: 1;
}

.coupon-card:hover {
    transform: translateY(-5px);
}

.view-coupon-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    overflow: hidden;
}

.coupon-header {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 8px 8px 0 0;
}

.coupon-title {
    font-weight: bold;
}

.coupon-discount {
    min-height: 30px;
    /* font-size: 1.5rem; */
}

.coupon-footer {
    margin-top: auto; /* Ensures the footer is pushed to the bottom */
}
  
.coupon-footer button {
    flex-grow: 1;
    margin: 0 3px;
    border-radius: 5px;
}
  
.coupon-footer button:hover {
    opacity: 0.9;
}

.coupon-header-reward {
    background: linear-gradient(to right, #00376e, #2b578e, #4f70a8, #6386ba);
    color: #ffffff;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.coupon-body {
    padding: 10px;
    text-align: center;
}

.coupon-body h5 {
    font-size: 1.5em;
    margin: 10px 0;
    color: #ff6f61;
}

.coupon-body h3 {
    font-size: 1.5em;
    margin: 0px 0;
    color: #ff6f61;
}

.coupon-body p {
    margin: 10px 0;
    color: #333333;
}

.coupon-body .validity span {
    font-weight: bold;
}

.coupon-code {
    background-color: #f7f7f7;
    border: 1px dashed #cccccc;
    border-radius: 5px;
    padding: 2px;
    font-size: 13px;
    margin: 20px 0;
}

.coupon-code strong {
    color: #ff6f61;
}

.coupon-details {
    text-align: center;
    margin-top: 20px;
    min-height: 40px;
}

.detail-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding: 5px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.detail-item:nth-child(odd) {
    background-color: #e9e9e9;
}

.detail-title {
    font-weight: bold;
    color: #333333;
}

.coupon-footer {
    background-color: #f1f1f1;
    padding: 15px;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.redeem-button {
    border: none;
    color: #ffffff;
    cursor: pointer;
    padding: 3px 6px;
    font-size: 14px;
    border-radius: 20px;
    margin: 5px;
}

.details-button {
    background-color: #5bc0de;
}

.details-button:hover {
    background-color: #46b8da;
}

.modify-button {
    background-color: #f0ad4e;
}

.modify-button:hover {
    background-color: #ec971f;
}

.remove-button {
    background-color: #d9534f;
}

.remove-button:hover {
    background-color: #c9302c;
}

.special-offer {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    color: #856404;
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0;
    font-weight: bold;
}