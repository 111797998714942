.cardHover{
    position: "relative";
    width: "300px";
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.cardHover:hover{
    transform: scale(1.02);
    z-index: 1;
}

.price-text {
  font-size: 10px;
}

.three-buttons {
  font-size: 10px !important;
}


@media screen and ( max-width: 1400px ) {    
  .price-text {
    font-size: 12px;
  }

  .three-buttons {
    font-size: 8px,
  }
}

@media screen and ( max-width: 1280px ) {    
  .price-text {
    font-size: 7.7px;
  }

  .three-buttons {
    font-size: 1px !important;
  }
}

@media screen and ( max-width: 1200px ) {    
  .price-text {
    font-size: 8.9px;
  }

  .three-buttons {
    font-size: 7px !important;
  }
}

@media print {
    body * {
      display: none; /* Hide everything */
    }
    #printableTable,
    #printableTable * {
      display: table; /* Show the table and its contents */
    }
  }
  